import React, { useEffect, useState, Component } from 'react';
import { Link, useHistory } from 'react-router-dom';
import ReactHtmlParser from 'react-html-parser';
import Pagination from 'react-js-pagination';
import {
  FaAngleLeft,
  FaAngleRight,
  FaAngleDoubleLeft,
  FaAngleDoubleRight,
  FaPencilAlt,
} from 'react-icons/fa';
import { FiFilePlus, FiLogOut } from 'react-icons/fi';

import scrollTop from '../../utils/scrollTop';
import queryfie from '../../utils/queryfie';
import api from '../../services/api';
import './styles.css';

export default function Publications(props) {
  const params = queryfie(props.location.search);
  const [news, setNews] = useState([]);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(params.page || 1);
  const [pagination, setPagination] = useState('');
  const [edit, setEdit] = useState('');
  const [bar, setBar] = useState('');
  const history = useHistory();

  const linkToEdit = (news) => {
    return edit ? (
      <Link to={`/Editor?news=${news}`}>
        <FaPencilAlt size={23} />
      </Link>
    ) : null;
  };

  useEffect(() => {
    props.authorize.then((auth) => {
      if (auth) {
        setEdit(auth);
        setBar(
          <div className='container mb--30 a-user'>
            <Link
              to='/editor'
              onClick={() => scrollTop}
              title='criar nova publicação'
            >
              <FiFilePlus size={30} />
            </Link>
            <Link
              to='#'
              onClick={() => {
                localStorage.clear();
                window.location.reload();
              }}
              title='Deslogar'
            >
              <FiLogOut size={30} />
            </Link>
          </div>
        );
      }
    });
  }, [props.authorize]);

  class Paginate extends Component {
    constructor(props) {
      super(props);
      this.state = {
        activePage: props.activePage || Number(params.page) || 1,
        totalItens: props.totalItens,
      };
    }

    handlePageChange(pageNumber) {
      this.setState({ activePage: pageNumber });
      history.push(`/Publications?page=${pageNumber}`);
      setPage(pageNumber);
      scrollTop();
    }

    render() {
      return (
        <>
          <Pagination
            activePage={this.state.activePage}
            itemsCountPerPage={5}
            totalItemsCount={this.state.totalItens}
            onChange={this.handlePageChange.bind(this)}
            prevPageText={<FaAngleLeft />}
            nextPageText={<FaAngleRight />}
            firstPageText={<FaAngleDoubleLeft />}
            lastPageText={<FaAngleDoubleRight />}
          />
        </>
      );
    }
  }

  useEffect(() => {
    async function loadNews() {
      await api
        .get('publication', {
          params: { page },
        })
        .then((response) => {
          setNews(response.data);
          setTotal(Number(response.headers['x-total-count']));
        });
    }
    setTimeout(() => {
      if(document.querySelector('.news-area')) {
        document.querySelector('.news-area').style.opacity = 1;
      }
    }, 150);
    loadNews();
  }, [page]);

  useEffect(() => {
    if (total > 5) setPagination(<Paginate totalItens={total} />);
  }, [total]);

  return (
    <div className='news-area' id='publications'>
      {bar}
      {news.length > 0 ? news.map((n) => (
        <div className='news-card' key={n.id}>
          <div className='container'>
            <div className='row card-shadow'>
              <div className='col-lg-4'>
                <div className='thumbnail tn-hover'>
                  <Link
                    className='btn-link'
                    to={`/news/${n.id}`}
                    onClick={scrollTop}
                  >
                    Ver Detalhes
                  </Link>
                  <img
                    className='w-100'
                    src={
                      n?.image
                        ? `${process.env.REACT_APP_API_URL}/files/${n.image}`
                        : require('../../assets/logo.jpeg')
                    }
                    alt={n.title}
                  />
                </div>
              </div>
              <div className='col-lg-8'>
                <div className='about-inner inner'>
                  <div className='section-title title-link'>
                    <h5>
                      {new Date(n.date).toLocaleDateString()}
                      {linkToEdit(n.id)}
                    </h5>
                    <div
                      className='goto'
                      onClick={() => {
                        history.push(`/news/${n.id}`);
                      }}
                    >
                      <h2 className='title'>{n.title}</h2>
                      <div className='description'>
                        {ReactHtmlParser(n.description.substr(0, 250) + ' ...')}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )) : <div className="text-center no-records">Nenhuma publicação encontrada!</div>}
      <div className='container paginate'>{pagination}</div>
    </div>
  );
}
