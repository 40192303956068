import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import SunEditor from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css'; // Import Sun Editor's CSS File

import api from '../../services/api';
import queryfie from '../../utils/queryfie';

import ImageUpload from '../ImageUpload/index';
import './styles.css';

const MyComponent = (props) => {
  const params = queryfie(props.location.search);
  const history = useHistory();
  const [auth, setauth] = useState('');
  const [operation, setOperation] = useState('create');
  const [description, setDescription] = useState(
    localStorage.getItem('content')
  );
  const [title, setTitle] = useState('');
  const [buttons, setButtons] = useState(
    <div className='col-12'>
      <button type='submit' className='button'>
        Salvar
      </button>
    </div>
  );

  const btnConfigs = [
    ['undo', 'redo'],
    ['fontSize', 'formatBlock'],
    ['paragraphStyle', 'blockquote'],
    ['bold', 'underline', 'italic', 'strike', 'subscript', 'superscript'],
    ['fontColor', 'hiliteColor', 'textStyle'],
    ['removeFormat'],
    ['outdent', 'indent'],
    ['align', 'horizontalRule', 'list', 'lineHeight'],
    ['table', 'link', 'image', 'video'],
  ];

  useEffect(() => {
    props.authorize.then((e) => {
      if (e) {
        setauth(e);
      } else {
        history.push('/Publications');
      }
    });

    if (params.news) {
      setButtons(
        <>
          <div className='col-6'>
            <button
              type='submit'
              className='button'
              onClick={() => setOperation('delete')}
            >
              Deletar
            </button>
          </div>
          <div className='col-6'>
            <button
              type='submit'
              className='button'
              onClick={() => setOperation('update')}
            >
              Editar
            </button>
          </div>
        </>
      );

      api.get(`publication/${params.news}`).then((res) => {
        if (res.data.image) {
          document.querySelector('.imgPreview').innerHTML =
            '<img  alt="Preview da imagem importada"/>';
          document.querySelector(
            '.imgPreview img'
          ).src = `${process.env.REACT_APP_API_URL}/files/${res.data.image}`;
        }
        setDescription(res.data.description);
        setTitle(res.data.title);
      });
    }
  }, [history, params.news, props.authorize]);

  function handleChange(content) {
    localStorage.setItem('content', content);
  }

  async function handleSave(e) {
    e.preventDefault();
    try {
      const form = new FormData();
      form.append('file', document.querySelector('.fileInput').files[0]);
      form.append('title', title);

      form.append('description', localStorage.getItem('content'));

      const token = localStorage.getItem('token');

      if (operation === 'create') {
        await api.post('publication', form, {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${token}`,
          },
        });
      } else if (operation === 'update') {
        form.append('id', params.news);

        await api.put('publication', form, {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${token}`,
          },
        });
      } else {
        await api.delete(`publication/${params.news}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
      }

      localStorage.setItem('content', '');
      history.push('/Publications');
    } catch (err) {
      alert('Falha ao criar publicação!' + err);
    }
  }

  return (
    <div className='new-news'>
      <h5>Criar nova publicação</h5>
      <form onSubmit={handleSave}>
        <div className='row  mb--30'>
          <div className='col-lg-4'>
            <ImageUpload />
          </div>
          <div className='col-lg-8'>
            <input
              className='mb--30 '
              type='text'
              value={title}
              placeholder='Título da matéria'
              onChange={(e) => setTitle(e.target.value)}
            />
            <SunEditor
              onChange={handleChange}
              setContents={description}
              setOptions={{
                height: '100%',
                buttonList: btnConfigs,
              }}
            />
            <div className='row'>{buttons}</div>
          </div>
        </div>
      </form>
    </div>
  );
};
export default MyComponent;
