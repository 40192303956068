import React from "react";

export default function Mainlayout(props) {

  return (
    <>
     {props.children}
    </>
  );
}
