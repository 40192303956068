import React from 'react';
import './styles.css';
function ImageUpload(props) {
  const [imagePreviewUrl, setImagePreviewUrl] = React.useState('');

  function handleImageChange(e) {
    let file = e.target.files[0];
    if (file) {
      setImagePreviewUrl(URL.createObjectURL(file));
    }
  }

  return (
    <div className='previewComponent'>
      <input
        className='fileInput'
        type='file'
        onChange={(e) => handleImageChange(e)}
      />
      <div className='imgPreview mb--30'>
        {imagePreviewUrl && (
          <img src={imagePreviewUrl} alt='Preview da imagem importada' />
        )}
      </div>
    </div>
  );
}

export default ImageUpload;
