import React from 'react';
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import { FaEnvelope, FaPhone, FaLinkedin, FaWhatsapp } from 'react-icons/fa';
import logoImg from './assets/logo-certo.png';
import textoImg from './assets/logo-fonte.svg';
import scrollTop from './utils/scrollTop';
import Mapa from '../src/assets/mapa.png';

export default function MainLayout(props) {
  window.onload = function () {
    document
      .querySelectorAll('a')
      .forEach((btnList) => btnList.addEventListener('click', handleClass));
  };

  // window.addEventListener('scroll', function (e) {
  //   const header = document.getElementById('header');
  //   if (header) {
  //     if (window.scrollY > 0) {
  //       header.classList.add('fixed');
  //     } else {
  //       header.classList.remove('fixed');
  //     }
  //   }
  // });

  // function showDropdown() {
  //   document.getElementById('dd_publicacoes').classList.add('dropdown-active');
  // }

  // function hideDropdown() {
  //   document
  //     .getElementById('dd_publicacoes')
  //     .classList.remove('dropdown-active');
  // }

  const scrollWithOffset = (el, offset) => {
    const elementPosition = el.offsetTop - offset;
    window.scroll({
      top: elementPosition,
      left: 0,
      behavior: 'smooth',
    });
  };
  function handleClass() {
    document.querySelector('.ham').classList.toggle('active');
    document.querySelector('.navs-section').classList.toggle('active');
  }

  return (
    <div className='profile-container'>
      <header id='header' className='fixed'>
        <Link className='logo-section' to='/' onClick={scrollTop}>
          <img src={logoImg} alt='Pavanni & Lollo' id='logo' className='show' />
          <img
            src={textoImg}
            alt='Sociedade de advogados'
            id='slogan'
            className='show'
          ></img>
        </Link>

        <div className='navs-section'>
          <HashLink className='btn-list' smooth to='/#home' exact={'true'}>
            <span>Home</span>
          </HashLink>
          <HashLink
            className='btn-list'
            smooth
            to='/#atuacao'
            scroll={(el) => scrollWithOffset(el, 45)}
          >
            <span>Atuação</span>
          </HashLink>
          <HashLink
            className='btn-list'
            smooth
            to='/#equipe'
            scroll={(el) => scrollWithOffset(el, -1)}
          >
            <span>Equipe</span>
          </HashLink>
          <HashLink
            className='btn-list'
            smooth
            to='/#missao'
            scroll={(el) => scrollWithOffset(el, 80)}
          >
            <span>Missão</span>
          </HashLink>
          <Link to='/Publications' onClick={scrollTop} className='btn-list'>
            <span>Publicações</span>
          </Link>
        </div>
        <svg
          className='ham hamRotate ham8'
          viewBox='0 0 100 100'
          width='80'
          onClick={(e) => {
            handleClass();
          }}
        >
          <path
            className='line top'
            d='m 30,33 h 40 c 3.722839,0 7.5,3.126468 7.5,8.578427 0,5.451959 -2.727029,8.421573 -7.5,8.421573 h -20'
          />
          <path className='line middle' d='m 30,50 h 40' />
          <path
            className='line bottom'
            d='m 70,67 h -40 c 0,0 -7.5,-0.802118 -7.5,-8.365747 0,-7.563629 7.5,-8.634253 7.5,-8.634253 h 20'
          />
        </svg>
      </header>
      <div className='body'>
        {props.children}

        <footer className='row' id='footer'>
          <div>
            <span className='line-left'></span>
            <span className='logo-text'>
              <img src={textoImg} alt='Pavani & Lollo sociedade de advogados' />
            </span>
            <span className='line-right'></span>
          </div>
          <div className='col-12 col-md-4'>
            <div className='contacts'>
              {/* <span>
                <a href='tel:+1932360578'>
                  <FaPhone size={22} />
                  (19) 3236-0578
                </a>
              </span> */}
              <span>
                <a href='https://wa.me/5519993206034'>
                  <FaWhatsapp size={26} />
                  (19) 99480-8945
                </a>
              </span>
              <span className='bold mt-20'>
                <FaEnvelope size={22} />
                <a href='mailto:contato@pavanielollo.adv.br' target='blank'>
                  contato@pavanielollo.adv.br
                </a>
              </span>
            </div>
            <div className='social-media'>
              {/* <a href='facebook.com'>
                <FaFacebookSquare size={35} />
              </a> */}
            </div>
          </div>
          <div className='col-12 col-md-8'>
            <div className='address'>
              <iframe
                src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3675.553869161032!2d-47.07339252473982!3d-22.892933137375927!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94c8c61d993bdd79%3A0x4c7f48f15e02bf70!2sRua%20Frei%20Ant%C3%B4nio%20de%20P%C3%A1dua%2C%20560%20-%20Jardim%20Guanabara%2C%20Campinas%20-%20SP%2C%2013073-330!5e0!3m2!1spt-BR!2sbr'
                id='mapa'
                allowFullScreen
                loading='lazy'
              ></iframe>
            </div>
          </div>
          <div class='text-center d-flex justify-content-center w-100 mt-3'>
            <div style={{ color: '#ccc' }}>
              © {new Date().getFullYear()}, made with
            </div>{' '}
            <div style={{ color: '#ff0000' }}>&nbsp; ❤ &nbsp;</div>
            <a
              href='https://xcoding.com.br'
              target='_blank'
              rel='noopener noreferrer'
              style={{ color: '#fff' }}
            >
              by Xcoding
            </a>
          </div>
        </footer>
      </div>
    </div>
  );
}
