import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import api from '../../services/api';

import logoText from '../../assets/logo-fonte.svg';
import Campinas from '../../assets/campinas.jpg';

import './styles.css';

export default function Logon() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const history = useHistory();

  async function handleLogin(e) {
    e.preventDefault();

    try {
      const response = await api.post('sessions', { email, password });

      localStorage.setItem('token', response.data.token);

      history.push('/');
      window.location.reload();
    } catch (err) {
      alert('Falha ao logar!');
    }
  }

  return (
    <div className='logon-container'>
      <img className='bg-campinas' src={Campinas} alt='Cidade de Campinas-SP' />

      <section className='form'>
        <form onSubmit={handleLogin}>
          <h1>Faça seu login</h1>

          <input
            placeholder='E-mail'
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />

          <input
            placeholder='Senha'
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            type='password'
          />

          <button type='submit' className='button'>
            Entrar
          </button>
        </form>
      </section>
      <img src={logoText} className='logo-text' alt='Pavani & Lollo' />
    </div>
  );
}
