import React from 'react';
import { FaChevronDown } from 'react-icons/fa';
import Slogan from '../../assets/slogan.svg';
import { HashLink } from 'react-router-hash-link';
import AwesomeSlider from 'react-awesome-slider';
import withAutoplay from 'react-awesome-slider/dist/autoplay';
import 'react-awesome-slider/dist/styles.css';
import logoImg from '../../assets/logo-certo.png';
import textoImg from '../../assets/logo-fonte.svg';
import foto1 from '../../assets/foto1.jpg';
import foto2 from '../../assets/foto2.jpg';
import foto3 from '../../assets/foto3.jpg';
import foto4 from '../../assets/foto4.jpg';
import lawyers from '../../assets/Lawyers.jpg'
import { useEffect } from 'react';
import api from '../../services/api';

const AutoplaySlider = withAutoplay(AwesomeSlider);

export default function MyLandingPage() {
  const [news, setNews] = React.useState([]);
  useEffect(() => {
    async function data() {
      const { data } = await api.get('home');
      setNews(data);
    }
    data();
  }, []);
  return (
    <>
      <div style={{ height: '45vh', background: '#560e0c' }}>
        <div style={{ paddingTop: '80px' }}>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <img
              src={logoImg}
              alt='Pavanni & Lollo'
              id='logo'
              style={{ height: '10vh' }}
            />
          </div>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <img
              src={textoImg}
              alt='Sociedade de advogados'
              id='slogan'
              className='show'
              style={{ height: '15vh' }}
            />
          </div>
        </div>
      </div>
      
      <AutoplaySlider
        play={true}
        cancelOnInteraction={false} // should stop playing on user interaction
        interval={2300}
        style={{ height: '55vh', background: '#000' }}
      >
        <div
          className='parallax-container'
          style={{
            backgroundImage: `url(${lawyers})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'contain',
            backgroundPosition: 'right',
          }}
        >
          <span className='parallax-slogan'>
            <img
              src={Slogan}
              alt='Pavani & Lollo sociedade de advogados'
              style={{ width: '35vw' }}
            />
          </span>
          <span className='parallax-icons'>
            <HashLink to='/#footer' smooth>
              <span className='scroll-down'>Saiba mais</span>
              <FaChevronDown size={36} color='#fff' style={{ width: '100%' }} />
            </HashLink>
          </span>
        </div>
        <div
          className='carrousel'
          data-src={foto1}
          style={{
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
          }}
        >
          <a href={news[0]?.id ? `news/${news[0]?.id}` : '/publications'}>
            <h5
              className='text-carrousel'
              style={{ color: '#fff', position: 'relative' }}
              id='foto1'
            >
              {news[0]?.title || 'Acessoria em serviços jurídicos'}
              <br />
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  paddingTop: '20px',
                  color: '#efca7c',
                }}
              >
                <small>Clique e saiba mais</small>
              </div>
            </h5>
          </a>
        </div>
        <div
          className='carrousel'
          style={{
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
          }}
          data-src={foto2}
        >
          <a href={news[1]?.id ? `news/${news[1]?.id}` : '/publications'}>
            <h5
              className='text-carrousel'
              style={{ color: '#fff', position: 'relative' }}
              id='foto1'
            >
              {news[1]?.title || 'Acessoria em serviços jurídicos'}
              <br />
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  paddingTop: '20px',
                  color: '#efca7c',
                }}
              >
                <small>Clique e saiba mais</small>
              </div>
            </h5>
          </a>
        </div>
        <div
          className='carrousel'
          data-src={foto3}
          style={{
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
          }}
        >
          <a href={news[2]?.id ? `news/${news[2]?.id}` : '/publications'}>
            <h5
              className='text-carrousel'
              style={{ color: '#fff', position: 'relative' }}
              id='foto1'
            >
              {news[2]?.title || 'Acessoria em serviços jurídicos'}
              <br />
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  paddingTop: '20px',
                  color: '#efca7c',
                }}
              >
                <small>Clique e saiba mais</small>
              </div>
            </h5>
          </a>
        </div>
        <div
          className='carrousel'
          data-src={foto4}
          style={{
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
          }}
        >
          <a href={news[3]?.id ? `news/${news[3]?.id}` : '/publications'}>
            <h5
              className='text-carrousel'
              style={{ color: '#fff', position: 'relative' }}
              id='foto1'
            >
              {news[3]?.title || 'Acessoria em serviços jurídicos'}
              <br />
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  paddingTop: '20px',
                  color: '#efca7c',
                }}
              >
                <small>Clique e saiba mais</small>
              </div>
            </h5>
          </a>
        </div>
      </AutoplaySlider>
    </>
  );
}
