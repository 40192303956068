import api from './services/api';

export default async function isAuthorized() {
  const token = localStorage.getItem('token');
  try {
    const response = await api({
      method: 'post',
      url: '/isAuthorized',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (response.status === 200) {
      return true;
    } else {
      return false
    }
  } catch (error) {
    return false;
  }
}
