import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

import isAuthenticated from './auth';

import MainLayout from './MainLayout';
import SecundaryLayout from './SecundaryLayout';
import EmptyLayout from './EmptyLayout';

import Home from './pages/Home';
import Login from './pages/Login';
import Publications from './pages/Publications';
import News from './pages/News';
import Editor from './pages/Editor';

export default function Routes() {
  const AppRoute = ({
    component: Component,
    layout: Layout,
    authorize,
    ...rest
  }) => (
    <Route
      {...rest}
      render={(props) => (
        <Layout>
          <Component {...props} authorize={authorize} />
        </Layout>
      )}
    />
  );

  return (
    <BrowserRouter>
      <Switch>
        <AppRoute path='/' exact component={Home} layout={MainLayout} />
        <AppRoute
          exact
          path='/publications'
          component={Publications}
          layout={SecundaryLayout}
          authorize={isAuthenticated()}
        />
        <AppRoute
          exact
          path='/news/:id'
          component={News}
          layout={SecundaryLayout}
        />
        <AppRoute
          exact
          path='/editor'
          component={Editor}
          layout={SecundaryLayout}
          authorize={isAuthenticated()}
        />
        <AppRoute exact path='/login' component={Login} layout={EmptyLayout} />
      </Switch>
    </BrowserRouter>
  );
}
