import React from 'react';
import {
  FaWhatsapp,
  FaBalanceScale,
  FaEnvelope,
  FaBriefcase,
  FaBriefcaseMedical,
  FaFileSignature,
  FaLandmark,
} from 'react-icons/fa';
import BgParallax from '../BgParallax/index';
import './styles.css';
import ScrollAnimation from 'react-animate-on-scroll';

export default function Home() {
  return (
    <>
      <div id='home'>
        <BgParallax />
      </div>
      <div id='atuacao' className='container-lg'>
        <div className='row'>
          <div className='col-lg-12'>
            <div className='row justify-content-center'>
              <div className='mb-30 col-lg-4 col-md-4 col-sm-6 col-12 text-left'>
                <div className='service service__style--2'>
                  <div className='icon'>
                    <FaBriefcaseMedical size={45} />
                  </div>
                  <div className='content'>
                    <h3 className='title'>Direito médico</h3>
                    <p>
                      Atuamos em defesa do médico em processos administrativos e
                      judiciais.
                    </p>
                  </div>
                </div>
              </div>
              <div className='mb-30 col-lg-4 col-md-4 col-sm-6 col-12 text-left'>
                <div className='service service__style--2'>
                  <div className='icon'>
                    <FaBalanceScale size={45} />
                  </div>
                  <div className='content'>
                    <h3 className='title'>Direito administrativo</h3>
                    <p>
                      Prestamos serviços de assessoria administrativa junto a
                      orgãos públicos municipais, estaduais e federais, e
                      atuamos em processos disciplinares em todas essas esferas.
                    </p>
                  </div>
                </div>
              </div>
              <div className='mb-30 col-lg-4 col-md-4 col-sm-6 col-12 text-left'>
                <div className='service service__style--2'>
                  <div className='icon'>
                    <FaFileSignature size={45} />
                  </div>
                  <div className='content'>
                    <h3 className='title'>Direito civil</h3>
                    <p>
                      Atuamos em direito da Família e Sucessões, direito do
                      consumidor e contratos em geral
                    </p>
                  </div>
                </div>
              </div>
              <div className='mb-30 col-lg-6 col-md-6 col-sm-6 col-12 text-left'>
                <div className='service service__style--2'>
                  <div className='icon'>
                    <FaBriefcase size={45} />
                  </div>
                  <div className='content'>
                    <h3 className='title'>Direito trabalhista</h3>
                    <p>
                      Atuamos nos mais diversos campos das relações
                      trabalhistas, seja na área trabalhista preventiva,
                      consultiva, contenciosa em todas as instâncias judiciais e
                      na área administrativa.
                    </p>
                  </div>
                </div>
              </div>
              <div className='mb-30 col-lg-6 col-md-6 col-sm-6 col-12 text-left'>
                <div className='service service__style--2'>
                  <div className='icon'>
                    <FaLandmark size={45} />
                  </div>
                  <div className='content'>
                    <h3 className='title'>Direito previdenciário</h3>
                    <p>
                      Prestamos serviços de assessoria e consultoria,
                      administrativa junto ao INSS e orgão público, com análise
                      de contribuições, requerimento de benefícios e
                      apresentação de recurso. Propomos ações judicias para
                      implantação de aposentadorias rejeitadas na esfera
                      administrativa.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ScrollAnimation animateIn='fadeIn' offset={200}>
        <div id='equipe'>
          <div className='row container-lg justify-content-center'>
            <div className='col-12 apresentation'>Nossa equipe</div>
            <br />
            <p class='text-center'>
              A Pavani & Lollo Sociedade de Advogados conta com uma equipe
              altamente qualificada, tendo destaque na Cidade de Campinas pela
              excelente visão estratégica, competência e alto conhecimento
              jurídico de seus profissionais, aliados a ampla experiência nas
              mais diversas áreas de atuação do Direito, proporcionando
              segurança e confiabilidade à seus clientes.
            </p>
            <br />
            {/* <div className='col-xl-4 col-lg-4 col-md-5 col-sm-8 col-10 mt--30'>
              <div className='team-static'>
                <div className='preview'>
                  <ul className='social-transparent'>
                    <li>
                      <a href='mailto:contato@pavanielollo.adv.br'>
                        <FaEnvelope size={20} color='#fff' />
                      </a>
                    </li>
                    <li>
                    <a href='https://api.whatsapp.com/send?phone=5519974128392'>
                        <FaWhatsapp size={20} color='#fff' />
                      </a>
                    </li>
                  </ul>

                  <img
                    className='img-adv'
                    src={Advogado1}
                    alt='Patricia Pavani'
                  />
                </div>

                <div className='inner'>
                  <div className='content text-center'>
                    <h4 className='title'>Patricia Pavani</h4>
                    <p className='designation'>Advogada</p>
                  </div>
                </div>
              </div>
            </div> */}
            <div className='mb-30 col-xl-4 col-lg-5 col-md-6 col-sm-8 col-12 text-left'>
              <div className='service service__style--2 user'>
                <div className='icon'>
                  <a href='mailto:patricia@pavanielollo.adv.br'>
                    <FaEnvelope size={35} />
                  </a>
                  <a href='https://api.whatsapp.com/send?phone=5519974128392'>
                    <FaWhatsapp size={35} />
                  </a>
                </div>
                <div className='content'>
                  <h3 className='title'>Patricia Pavani</h3>
                  <h6>OAB 308532/SP</h6>
                </div>
              </div>
            </div>
            <div className='mb-30 col-xl-4 col-lg-5 col-md-6 col-sm-8 col-12 text-left'>
              <div className='service service__style--2 user'>
                <div className='icon'>
                  <a href='mailto:silvia@pavanielollo.adv.br'>
                    <FaEnvelope size={35} />
                  </a>
                  <a href='https://api.whatsapp.com/send?phone=5519993080151'>
                    <FaWhatsapp size={35} />
                  </a>
                </div>
                <div className='content'>
                  <h3 className='title'>Silvia Lollo</h3>
                  <h6>OAB 331145/SP</h6>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ScrollAnimation>
      <div id='missao'>
        <ScrollAnimation animateIn='fadeIn'>
          <h1 className='text-center'>Missão, Visão e Valores</h1>

          <div className='container'>
            <h3>Missão</h3>
            <p>
              Compromisso total com os interesses dos nossos clientes,
              maximizando o desempenho com o foco no êxito, adesão aos mais
              altos padrões éticos, agregando excelência técnica a paixão pelo
              Direito.
            </p>
            <h3>Valores</h3>
            <p>
              Dinamismo, Ética, Meritocracia e Inovação são pilares que
              sustentam o escritório Pavani & Lollo.
            </p>
            <h3>Visão</h3>
            <p>
              Reconhecimento no mercado como referência na prestação de serviços
              jurídicos.
            </p>
          </div>
        </ScrollAnimation>
      </div>
    </>
  );
  /* 
      <div id="clientes">
        <h1 className="text-center">Nossos clientes</h1>
        <ScrollAnimation animateIn="fadeInUp" offset={50}>
          <div className="container">
            <div className="row">
              <div className="col-4 col-md-3">
                <div className="cliente-card">
                  <img src={Cliente1} alt="X-coding" />
                </div>
              </div>
              <div className="col-4 col-md-3">
                <div className="cliente-card">
                  <img src={Cliente2} alt="X-coding" />
                </div>
              </div>
              <div className="col-4 col-md-3">
                <div className="cliente-card">
                  <img src={Cliente1} alt="X-coding" />
                </div>
              </div>
              <div className="col-4 col-md-3">
                <div className="cliente-card">
                  <img src={Cliente2} alt="X-coding" />
                </div>
              </div>

              <div className="col-4 col-md-3">
                <div className="cliente-card">
                  <img src={Cliente1} alt="X-coding" />
                </div>
              </div>
              <div className="col-4 col-md-3">
                <div className="cliente-card">
                  <img src={Cliente2} alt="X-coding" />
                </div>
              </div>
              <div className="col-4 col-md-3">
                <div className="cliente-card">
                  <img src={Cliente1} alt="X-coding" />
                </div>
              </div>
              <div className="col-4 col-md-3">
                <div className="cliente-card">
                  <img src={Cliente2} alt="X-coding" />
                </div>
              </div>

              <div className="col-4 col-md-3">
                <div className="cliente-card">
                  <img src={Cliente1} alt="X-coding" />
                </div>
              </div>
              <div className="col-4 col-md-3">
                <div className="cliente-card">
                  <img src={Cliente2} alt="X-coding" />
                </div>
              </div>
              <div className="col-4 col-md-3">
                <div className="cliente-card">
                  <img src={Cliente1} alt="X-coding" />
                </div>
              </div>
              <div className="col-4 col-md-3">
                <div className="cliente-card">
                  <img src={Cliente2} alt="X-coding" />
                </div>
              </div>
            </div>
          </div>
        </ScrollAnimation>
      </div>
      */
}
